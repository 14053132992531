import {
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    DateInput,
    DisabledInput,
    Edit,
    EditButton,
    FormTab,
    ImageField,
    ImageInput,
    List,
    NumberInput,
    ReferenceArrayField,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    SaveButton,
    SelectInput,
    SelectField,
    SelectArrayInput,
    SimpleForm,
    TabbedForm,
    TextField,
    TextInput,
    Toolbar,
    DeleteButton,
    ChipField,
    FunctionField
} from 'admin-on-rest'
import RichTextInput from 'aor-rich-text-input'
import {Component, h} from '../reactAsH'
import {Field} from 'redux-form'
import dot from 'dot'
import {firebaseApp} from '../../firebase'
import {ConfigurationTypesArray, ConfigurationTypes} from '../../../common/ConfigurationTypes'


export const CiviActsList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="label" label="Nom"/>
            <FunctionField source="category"label="Catégorie" render={({category}) => Object.keys(category).map(key => ConfigurationTypes[category[key]]).join(', ')}/>
            <TextField source="flouz" label="Récompense"/>
            <TextField source="duration" label="durée"/>
            <DateField source="createdAt" label="Créé le"/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
)

export const CiviActsEdit = (props) => (
    <Edit {...props}>
        {Fields(true)}
    </Edit>
)
export const CiviActsCreate = (props) => (
    <Create {...props}>
        {Fields(false)}
    </Create>
)

const Fields = (isEdit) => <SimpleForm>
    {isEdit && <DisabledInput label="Id" source="id"/>}
    <TextInput source="label" label="Nom"/>
    <BooleanInput label="Ponctuel ?" source="isQRCode"/>
    <NumberInput source="duration" label="durée (en jours)"/>
    <NumberInput source="flouz" label="Récompense"/>
    <SelectArrayInput source="category" label="Catégorie" choices={ConfigurationTypesArray}/>
    <PreviewOneParticipant source="feedbackIn" label="Texte de lancement"/>
    <PreviewMultipleParticipants source="feedbackOut" label="Texte de fin"/>
</SimpleForm>
class PreviewOneParticipant extends Component {
    state = {
        duration  : 0,
        entreprise: 'CiviTime'
    }
    render = (props) => {
        return <div>
            <RichTextInput {...this.props}/>
            <Field name={this.props.source} {...this.props}
                   component={(props) => <PreviewRender {...props} entreprise={this.state.entreprise}
                                                        duration={this.state.duration}/>}/>
            <Field name="duration" source="duration" component={(props) => {
                if (props.input.value !== this.state.duration) {
                    this.setState({duration: props.input.value})
                }
                return null
            }}/>
        </div>
    }
}

class PreviewMultipleParticipants extends Component {
    state = {
        participants: [1, 100, 2000],
        value       : null,
    }

    calculateParticipantsList = (value) => {
        /*
         if (value === this.state.value) return
         requestAnimationFrame(async () => {
         const equivalences = formatEquivalence((await firebaseApp.database().ref('equivalences').once('value')).val())
         const template = dot.template(value)
         const items = []
         for (let i = 1; i <= 2000; i++) {
         let label = {}
         const result = template({
         equivalence: (value, unit) => {
         label = evaluateEquivalences(equivalences, value, unit, i).label
         return ' '
         }
         })
         if (items[items.length - 1]?.label !== label) {
         items.push({
         i, result, label
         })
         }
         }
         requestAnimationFrame(() => {
         this.setState({
         participants: items
         .map(({i}) => i)
         .reduce((acc, v) => [...acc, v-1, v], [])
         .filter(v => v > 0 && v < 2000)
         .concat(2000),
         value,
         })
         })
         })*/

    }

    render = () => {
        return <div>
            <RichTextInput {...this.props}/>
            <Field name={this.props.source} {...this.props} component={(props) => {
                //this.calculateParticipantsList(props.input?.value)
                return <div>
                    {/*
                     this.state.participants
                     .map(v =>
                     <div key={v} style={{display: 'flex', alignItems: 'center',}}>
                     {v} participants -&nbsp;<PreviewRender {...props} participants={v}/>
                     </div>
                     )
                     */}
                </div>
            }}/>
        </div>
    }
}

PreviewOneParticipant.defaultProps = {
    addField: true,
    addLabel: true,
}
PreviewMultipleParticipants.defaultProps = {
    addField: true,
    addLabel: true,
}

class PreviewRender extends Component {
    state = {
        rendered: '',
    }

    async componentDidMount() {
        await this.componentWillReceiveProps(this.props, this.state)
    }

    async componentWillReceiveProps({input: {value, ...input}, ...props}, state) {
        try {
            const equivalences = formatEquivalence((await firebaseApp.database().ref('equivalences').once('value')).val())
            const template = dot.template(value)
            const result = template({
                equivalence: (value, unit) => {
                    const equivalences1 = evaluateEquivalences(equivalences, value, unit, this.props.participants || 1)
                    const quantity = Math.round(equivalences1.value)
                    const pluralRegex = /\.([^ ]+)/
                    const label = quantity > 1 ? equivalences1.label.replace(pluralRegex, '$1') : equivalences1.label.replace(pluralRegex, '')
                    return quantity + ' ' + label
                },
                duration   : props.duration,
                entreprise : props.entreprise,
            })
            this.setState({
                rendered: result,
            })
        } catch (e) {
            console.error(e)
        }
    }

    render() {
        return <div dangerouslySetInnerHTML={{__html: this.state.rendered}}/>
    }
}

function formatEquivalence(equivalences) {
    return Object.keys(equivalences)
        .map(key => equivalences[key].types)
        .reduce((acc, item) => [...acc, ...item], [])
        .reduce((acc, equivalence) => [...acc, ...Object.keys(equivalence.units).map(subKey => ({...equivalence, ...equivalence.units[subKey]}))], [])
}

function evaluateEquivalences(equivalences, userValue, unit, quantity = 1) {
    return equivalences
        .filter(item => item.unit === unit)
        .map((item) => {
            const value = (userValue * quantity) / item.reference
            return ({
                ...item,
                value: Math.round(value),
                delta: Math.abs(Math.round(value) - value) / Math.abs(item.max - item.min),
                in   : value >= item.min && value <= item.max,
            })
        })
        .filter(({value}) => value > 0)
        .sort((item1, item2) => {
            if (item1.in && !item2.in) {
                return -1
            } else if (item1.in === item2.in) {
                if (item1.value === item2.value)
                    return item1.delta - item2.delta
                else
                    return item1.value - item2.value
            } else {
                return 1
            }
        })[0]
}
