import {
    BooleanInput,
    ChipField,
    Create,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    DisabledInput,
    Edit,
    EditButton,
    FileInput,
    FormTab,
    ImageField,
    ImageInput,
    List,
    ReferenceArrayField,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    RichTextField,
    SaveButton,
    SelectInput,
    SelectArrayInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    SingleFieldList,
    TabbedForm,
    TextField,
    TextInput,
    Toolbar,
    FunctionField, NumberInput, ReferenceArrayInput,
} from 'admin-on-rest'
import {h} from '../reactAsH'
import React from 'react'
import RichTextInput from 'aor-rich-text-input'
import {ConfigurationTypes, ConfigurationTypesArray} from '../../../common/ConfigurationTypes'


export const CiviJobList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="name" label="Nom"/>
            <FunctionField source="category"label="Catégorie" render={({category} = {}) => Object.keys(category || {}).map(key => ConfigurationTypes[category[key]]).join(', ')}/>
            <TextField source="key" label="Clé technique"/>
            <TextField label="dependsOn" source="dependsOn"/>
            <TextField source="cost" label="Coût"/>
            <TextField source="productionTime" label="Durée"/>
            <TextField source="socialPoints" label="Points Social"/>
            <TextField source="environmentPoints" label="Points Environnement"/>
            <EditButton/>
            <ShowButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
)

export const CiviJobCreate = (props) => (
    <Create {...props}>
        <TabbedForm redirect="list">
            <FormTab label="Nom du CiviJob">
                <TextInput source="name" label="Nom"/>
                <TextInput source="key" label="Clé technique"/>
                <SelectArrayInput source="category" label="Catégorie" choices={ConfigurationTypesArray}/>
                <NumberInput source="environmentPoints" label="Durée"/>
                <NumberInput source="socialPoints"/>
                <NumberInput source="cost" label="Coût"/>
                <NumberInput source="productionTime" label="Temp de production en heures"/>
                <ReferenceArrayInput label="Se débloque quand :" source="dependsOn" reference="civijobs">
                    <SelectArrayInput optionText="key" />
                </ReferenceArrayInput>
            </FormTab>
            <FormTab label="Feedback In">
                <RichTextInput source="feedbackIn" label="Description"/>
            </FormTab>
            <FormTab label="Feedback out">
                <RichTextInput source="feedbackOut" label="Description"/>
            </FormTab>
        </TabbedForm>
    </Create>
)

export const CiviJobView = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" label="Nom"/>
            <TextField source="key" label="Clé technique"/>
        </SimpleShowLayout>
    </Show>
)


export const CiviJobEdit = (props) => (
    <Edit {...props} redirect="list">
        <TabbedForm redirect="list">
            <FormTab label="Nom du CiviJob">
                <TextInput source="name" label="Nom"/>
                <TextInput source="key" label="Clé technique"/>
                <SelectArrayInput source="category" label="Catégorie" choices={ConfigurationTypesArray}/>
                <NumberInput source="environmentPoints"/>
                <NumberInput source="socialPoints"/>
                <NumberInput source="cost" label="Coût"/>
                <NumberInput source="productionTime" label="Temp de production en heures"/>
                <ReferenceArrayInput label="Se débloque quand :" source="dependsOn" reference="civijobs">
                    <SelectArrayInput optionText="key" />
                </ReferenceArrayInput>
            </FormTab>
            <FormTab label="Feedback In">
                <RichTextInput source="feedbackIn" label="Description"/>
            </FormTab>
            <FormTab label="Feedback out">
                <RichTextInput source="feedbackOut" label="Description"/>
            </FormTab>
        </TabbedForm>
    </Edit>
)
