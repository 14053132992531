export enum ConfigurationTypes {
    'consumtion' = 'Consommation responsable',
    'diversityEquality' = 'Diversité & Égalité',
    'energySaving' = 'Économie d\'énergie',
    'healthSecurity' = 'Santé & sécurité au travail',
    'wellBeing' = 'Qualité de vie au travail',
    'mobility' = 'Mobilité',
    'others' = 'Autres',
}

export const ConfigurationTypesArray = Object.keys(ConfigurationTypes).map(key => ({
    id  : key,
    name: ConfigurationTypes[key],
}))
