import {
    Create,
    CreateButton,
    Datagrid,
    DateField,
    DateInput,
    DisabledInput,
    Edit,
    EditButton,
    FormTab,
    ImageField,
    ImageInput,
    List,
    NumberInput,
    ReferenceArrayField,
    ReferenceManyField,
    SaveButton,
    SimpleForm,
    TabbedForm,
    TextField,
    TextInput,
    Toolbar
} from 'admin-on-rest'
import {h} from '../reactAsH'
import {EmbeddedArrayInput} from 'aor-embedded-array'

export const EquivalencesList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="name" title="Nom"/>
            <EditButton/>
        </Datagrid>
    </List>
)

export const EquivalencesCreate = (props) => (
    <Create {...props}>
        {Fields(false)}
    </Create>
)
export const EquivalencesEdit = (props) => (
    <Edit {...props}>
        {Fields(true)}
    </Edit>
)

const Fields = (isEdit) => <SimpleForm>
    {isEdit && <DisabledInput label="Id" source="id"/>}
    <TextInput source="name" label="Nom"/>
    <EmbeddedArrayInput source="types">
        <TextInput source="label" label="Label"/>
        <NumberInput source="min" label="Minimum"/>
        <NumberInput source="max" label="Maximum"/>
        <EmbeddedArrayInput source="units">
            <TextInput source="unit" label="Unité"/>
            <NumberInput source="reference" label="Valeur de référence"/>
        </EmbeddedArrayInput>
    </EmbeddedArrayInput>
</SimpleForm>
