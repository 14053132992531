import nanoid from 'nanoid'
import {AdminTypes} from '../../functions/admin/Admin.types'
import firebase from '../../node_modules/aor-firebase-client/node_modules/firebase'

window.test = firebase
export const restClientWrapper = (requestHandler, asArrays) => async (type, resource, params) => {
    return actionWrapper(type, resource, params)

    async function actionWrapper(type, resourceName, params) {
        const actions = firebase.database().ref('actions')
        const data = params.data || {}
        const itemId = data.id = data.key = data?.key || data?.id || params.id || params?.key || nanoid() || null
        const target = `${resourceName}/${itemId}`
        switch (type) {
            case 'DELETE':
                const deleteAwait = waitFor(target)
                await actions.push({
                    triggeredBy: firebase.auth().currentUser.uid,
                    time       : Date.now(),
                    type       : AdminTypes.delete,
                    payload    : {
                        target: target,
                        data  : data || {},
                    }
                })
                await deleteAwait
                return {
                    data: itemId,
                }

            case 'UPDATE':
            case 'CREATE':
                const uploadedData = await upload(resourceName, itemId, data)

                const {id, key, ...freshData} = uploadedData
                const updated = waitFor(target)
                await actions.push({
                    triggeredBy: firebase.auth().currentUser.uid,
                    type       : AdminTypes.update,
                    payload    : {
                        target: target,
                        data  : convertArrayAsObject(freshData, asArrays[resourceName]),
                    }
                })
                const oldTarget = ` ${resourceName}/${params.id}`
                if (id !== params.id) {
                    await actions.push({
                        triggeredBy: firebase.auth().currentUser.uid,
                        time       : Date.now(),
                        type       : AdminTypes.delete,
                        payload    : {
                            target: oldTarget,
                            data  : data || {},
                        }
                    })
                }
                await updated
                return {
                    data: uploadedData,
                }

            default:
                const result = await requestHandler(type, resourceName, params)
                return {
                    ...result,
                    data: convertObjAsArray(result.data, asArrays[resourceName])
                }

        }
    }
}

async function upload(resource, id, data) {
    switch (resource) {
        case 'civijob':
            if (data.models && data.models.length) {
                const newModels = data.models.filter(p => p.rawFile instanceof File)
                const newFiles = await uploadImages(`civijobs/${id}/model`, newModels)

                const result = {
                    ...data,
                    model: newFiles[0],
                }
                delete result.models
                return result
            }
            return data
        case 'map':
            if (data.models && data.models.length) {
                const newModels = data.models.filter(p => p.rawFile instanceof File)
                const newFiles = await uploadImages(`map/${id}/model`, newModels)

                const result = {
                    ...data,
                    model: newFiles[0],
                }
                delete result.models
                return result
            }
            return data
        default:
            return data
    }
}

async function uploadImages(path, images) {
    const storage = firebase.storage().ref()
    const avatarsRef = storage.child(path)
    return await Promise.all(images.map(async ({rawFile}) => {
        const storedFile = await avatarsRef.put(rawFile)
        return await storedFile.ref.getDownloadURL()
    }))
}

function waitFor(resource) {
    return new Promise((resolve) => {
        setTimeout(resolve, 1500)
    })
}

function convertObjAsArray(data, keys) {
    if (!keys) return data
    if (Array.isArray(data)) return data.map(item => convertObjAsArray(item, keys))
    return Object
        .keys(data)
        .filter(key => keys.includes(key))
        .reduce((acc, key) => ({
            ...acc,
            [key]: asArray(acc[key] || {})
        }), data)
}

function asArray(obj) {
    return Object
        .keys(obj)
        .map(key => ({
            ...obj[key],
            key,
        }))
}

function convertArrayAsObject(data, keys) {
    if (!keys) return data
    return keys
        .reduce((acc, key) => ({
            ...acc,
            [key]: asObject(acc[key] || [])
        }), data)
}

function asObject(array) {
    if (array.length === 0) return ''
    return array.reduce((acc, {key, ...item}) => ({
        ...acc,
        [key || nanoid()]: item,
    }), {})
}

async function uploadModel(path, models) {
    const storage = firebase.storage().ref()
    const avatarsRef = storage.child(path)
    return await Promise.all(models.map(async ({rawFile}) => {
        const storedFile = await avatarsRef.put(rawFile)
        return await storedFile.ref.getDownloadURL()
    }))
}
