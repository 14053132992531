import {
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    DateInput,
    DisabledInput,
    Edit,
    EditButton,
    FormTab,
    ImageField,
    ImageInput,
    List,
    NumberInput,
    ReferenceArrayField,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    SaveButton,
    SelectInput,
    SelectArrayInput,
    SimpleForm,
    TabbedForm,
    TextField,
    TextInput,
    Toolbar,
    DeleteButton,
    ChipField,
    SelectField,
    FunctionField
} from 'admin-on-rest'
import RichTextInput from 'aor-rich-text-input'
import {h} from '../reactAsH'
import {EmbeddedArrayInput} from 'aor-embedded-array'
import {ConfigurationTypes, ConfigurationTypesArray} from '../../../common/ConfigurationTypes'

export const QuizList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="question" label="Question"/>
            <FunctionField source="category"label="Catégorie" render={({category}) => Object.keys(category).map(key => ConfigurationTypes[category[key]]).join(', ')}/>
            <TextField source="explanation" label="Explication"/>
            <DateField source="createdAt" label="Créé le"/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
)

export const QuizEdit = (props) => (
    <Edit {...props}>
        {Fields(true)}
    </Edit>
)
export const QuizCreate = (props) => (
    <Create {...props}>
        {Fields(false)}
    </Create>
)

const Fields = (isEdit) => <SimpleForm>
    {isEdit && <DisabledInput label="Id" source="id"/>}
    <TextInput source="question" label="Question"/>
    <SelectArrayInput source="category" label="Catégorie" choices={ConfigurationTypesArray}/>
    <RichTextInput source="explanation" label="Explications"/>
    <EmbeddedArrayInput source="responses">
        <TextInput source="response" label="Réponse"/>
        <BooleanInput source="valid" label="Est une bonne réponse ?"/>
    </EmbeddedArrayInput>
</SimpleForm>
