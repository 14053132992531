// @ts-ignore
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import {firebaseConfig} from './firebaseConfig'

export const firebaseApp = firebase.initializeApp(firebaseConfig)
export const auth = firebaseApp.auth()
export const database = firebaseApp.database()

export async function doesEmailExists(email) {
    const results = await auth.fetchProvidersForEmail(email)
    console.log(results)
    return results.length > 0
}
