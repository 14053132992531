
export function generateMapName(evaluatedJobs) {
    const name = evaluatedJobs.filter(({activated}) => activated)
        .map(({technicalKey}) => technicalKey)
        .sort()
        .join('_').toLowerCase()
    return `map_${name || 'default'}.jpg`
}

export function generateJobsMapName(evaluatedJobs) {
    const name = evaluatedJobs
        .map(({key}) => key)
        .sort()
        .join('_').toLowerCase()
    return `map_${name || 'default'}.jpg`
}
