import '@babel/polyfill'
import {Admin, Delete, Resource} from 'admin-on-rest'
import {AuthClient, RestClient} from 'aor-firebase-client'
import {firebaseConfig} from '../firebaseConfig'
import {h} from './reactAsH'
import ReactDOM from 'react-dom'
import {restClientWrapper} from './restClientWrapper'
import {CiviActsCreate, CiviActsEdit, CiviActsList} from './CiviActs/CiviActs'
import {EquivalencesCreate, EquivalencesEdit, EquivalencesList} from './Equivalences/Equivalences'
import {CiviJobCreate, CiviJobEdit, CiviJobList, CiviJobView} from './CiviJob/CiviJob'
import {QuizCreate, QuizEdit, QuizList} from './Quiz/Quiz'
import {MapPreview} from './MapPreview/MapPreview'

const clientOptions = {
    timestampFieldNames: {
        createdAt: 'createdAt',
        updatedAt: 'updatedAt'
    },
    trackedResources   : ['equivalences', 'civijobs', 'quiz', 'acts']
}

const authConfig = {
    userProfilePath: '/users/',
    userAdminProp  : 'isAdmin'
}

const asArrays = {}

const App = () => (
    <Admin
        restClient={restClientWrapper(RestClient(firebaseConfig, clientOptions), asArrays)}
        authClient={AuthClient(authConfig)}
        dashboard={MapPreview}
    >
        <Resource name="equivalences" list={EquivalencesList} create={EquivalencesCreate} edit={EquivalencesEdit}/>
        <Resource name="acts" list={CiviActsList} create={CiviActsCreate} edit={CiviActsEdit} remove={Delete}/>
        <Resource name="civijobs" list={CiviJobList} show={CiviJobView} create={CiviJobCreate} edit={CiviJobEdit}
                  remove={Delete}/>
        <Resource name="quiz" list={QuizList} create={QuizCreate} edit={QuizEdit} remove={Delete}/>
    </Admin>
)

ReactDOM.render(<App/>, document.querySelector('#app'))
