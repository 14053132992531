export const firebaseConfig = {
    apiKey           : 'AIzaSyAt61KR4AnLRYOy0Ws_QvIFj4-J_y403j8',
    authDomain       : 'civitime-manager.firebaseapp.com',
    databaseURL      : 'https://civitime-manager.firebaseio.com',
    projectId        : 'civitime-manager',
    storageBucket    : 'civitime-manager.appspot.com',
    messagingSenderId: '666937116780',
}/*
export const firebaseConfig = {
    apiKey           : 'AIzaSyC5h-r-sRiYrHv-71d1FSpR18vc_aC7Q4k',
    authDomain       : 'civitime-demo.firebaseapp.com',
    databaseURL      : 'https://civitime-demo.firebaseio.com',
    projectId        : 'civitime-demo',
    storageBucket    : 'civitime-demo.appspot.com',
    messagingSenderId: '1091703456330',
}*/
