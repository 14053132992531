import {route} from 'preact-router'

const {history} = require('./history')

const baseElement = document.querySelector('base')
export const baseUrl = baseElement
    ? baseElement.href.replace(window.location.origin, '') || '/'
    : '/'

export const goTo = (url: string) => {
    if (fromBaseUrl(window.location.href) === fromBaseUrl(url)) return
    history.push(fromBaseUrl(url))
}

export const fromBaseUrl = (url: string): string => {
    const toGoTo = url
        ? url.replace(window.location.origin, '').replace(baseUrl, '/') || ''
        : ''
    const s = removeDoubleSlash(baseUrl + toGoTo)
    return s
}

export const regexFromUrlPattern = (pattern: string): RegExp => new RegExp(
    '^' +
    removeDoubleSlash(
        fromBaseUrl(pattern).replace(/:[^/&?]+/g, '([^/]+)'),
    )
    + '$')

const removeDoubleSlash = (s: string): string => '/' + s.split('/').filter(Boolean).join('/')
