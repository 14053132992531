import {Component} from 'react'
import {h} from '../reactAsH'
import firebase from '../../../node_modules/aor-firebase-client/node_modules/firebase'
import {fromBaseUrl} from '../../utils/appRoute'
import {generateMapName} from '../../../common/generateMapName'


export class MapPreview extends Component {
    state = {
        jobs: []
    }

    async componentDidMount() {
        const THREE = window.THREE
        this.loader = new THREE.GLTFLoader()
        const jobsRef = firebase.database().ref('civijobs')
        const rawJobs = (await jobsRef.once('value')).val()
        const jobs = Object.keys(rawJobs).map(key => ({
            ...rawJobs[key],
            technicalKey: key,
        }))
        this.setState({
            jobs,
        })

        console.time('start')

        const width = 4420//14000
        const height = 3020//12000
        const scene = new THREE.Scene()

        const aspect = width / height
        const distance = 17
        const camera = new THREE.OrthographicCamera(-distance * aspect, distance * aspect, distance, -distance, 1, 1000)

        camera.position.set(-30, 30, 30) // all components equal

        camera.lookAt(scene.position) // or the origin
        scene.add(camera)

        camera.position.x -= 15
        camera.position.z += 10
        // LIGHTS

        const hemiLight = new THREE.HemisphereLight(0xEDE6CC, 0x0065F5, 0.4)
        hemiLight.position.set(0, 20, 0)
        scene.add(hemiLight)

        const dirLight = new THREE.DirectionalLight(0xFFFFFF, 0.7)
        dirLight.position.set(-0.4, 1.1, 1)
        dirLight.position.multiplyScalar(20)
        scene.add(dirLight)

        dirLight.castShadow = true

        dirLight.shadow.mapSize.width = 4096
        dirLight.shadow.mapSize.height = 4096

        const d = 40

        dirLight.shadow.camera.left = -d
        dirLight.shadow.camera.right = d
        dirLight.shadow.camera.top = d
        dirLight.shadow.camera.bottom = -d
        dirLight.shadow.camera.far = 1000

        dirLight.shadow.camera.far = 3500

        dirLight.shadow.bias = -0.000001
        //dirLight.shadowDarkness = 0.2
        dirLight.shadow.radius = 1

        const renderer = new THREE.WebGLRenderer({
            antialias            : true,
            preserveDrawingBuffer: true,
        })


        scene.background = new THREE.Color(0xf0f0f0)

        renderer.setClearColor('#000000')
        renderer.setPixelRatio(1)
        renderer.setSize(width, height)
        this.scene = scene
        this.camera = camera
        this.renderer = renderer
        this.mount.appendChild(this.renderer.domElement)

        renderer.gammaInput = true
        renderer.gammaOutput = true

        renderer.shadowMap.enabled = true
        renderer.shadowMap.type = THREE.PCFSoftShadowMap // default THREE.PCFShadowMap

        const renderPass = new THREE.RenderPass(scene, camera)
        // Setup SSAO pass
        //const ssaoPass = new THREE.SSAOPass(scene, camera)
        //ssaoPass.renderToScreen = true
        // Add pass to effect composer
        const composer = new THREE.EffectComposer(renderer)
        composer.addPass(renderPass)
        //composer.addPass(ssaoPass)
        console.timeEnd('start')
        console.time('two')


        THREE.DRACOLoader.setDecoderPath('https://threejs.org/examples/js/libs/draco/')
        this.loader.setDRACOLoader(new THREE.DRACOLoader())
        new Promise((resolve, reject) => {
            this.loader.load(fromBaseUrl('maps/base27/MAP.gltf'), (loadedItem) => {
                    console.timeEnd('two')
                    console.time('three')
                    const mesh = loadedItem.scene
                    mesh.castShadow = true
                    mesh.receiveShadow = true
                    mesh.traverse(function (node) {
                        if (node.isMesh) {
                            node.material.flatShading = false

                            if (node.material?.name.indexOf('_Glass') < 0) {
                                node.castShadow = true
                                node.receiveShadow = true
                            }

                            //const tempGeo = new THREE.Geometry().fromBufferGeometry(node.geometry)
                            //tempGeo.mergeVertices()
                            //tempGeo.computeVertexNormals()
                            //tempGeo.computeFaceNormals()
                            //node.geometry = new THREE.BufferGeometry().fromGeometry(tempGeo)
                        }
                    })
                    console.timeEnd('three')
                    console.time('four')
                    this.scene.add(mesh)
                    resolve()
                }, () => {
                },
                error => {
                    reject(error)
                    console.error('An error happened', error)
                })
        }).then(() => this.start())
    }


    start = () => {
        if (!this.frameId) {
            console.timeEnd('four')
            updateScene(this.scene, this.state.jobs)
            this.frameId = requestAnimationFrame(this.animate)
        }
    }

    componentWillUnmout() {
        this.stop = true
    }

    animate = () => {
        if (this.stop) return
        this.renderScene()
        this.frameId = window.requestAnimationFrame(this.animate)
    }

    renderScene() {
        this.renderer.render(this.scene, this.camera)
    }

    changeJobState = (toChange) => {
        const activated = !toChange.activated
        const jobs = this.state.jobs.map(job => job === toChange ? ({
            ...job,
            activated: activated,
        }) : job)

        this.setState({
            jobs,
        }, () => {
            updateScene(this.scene, this.state.jobs)
        })
    }

    lastRender = null
    test = async () => {
        const oldAfterRender = this.scene.onAfterRender
        const waitNextRender = () => new Promise(resolve =>
            this.scene.onAfterRender = () => setTimeout(resolve, 500)
        )
        const nextRender = waitNextRender()
        this.renderScene()
        await nextRender

        const data = dataURIToBlob(this.renderer.domElement.toDataURL('image/png'))

        downloadURI(URL.createObjectURL(data), name)
        this.lastRender = data

        this.scene.onAfterRender = oldAfterRender

        function downloadURI(uri, name) {
            const link = document.createElement('a')
            link.download = name
            link.href = uri
            document.body.appendChild(link)
            link.click()
            URL.revokeObjectURL(uri)
            document.body.removeChild(link)
        }

        function dataURIToBlob(dataURI) {

            var binStr = atob(dataURI.split(',')[1]),
                len = binStr.length,
                arr = new Uint8Array(len),
                mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

            for (var i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i)
            }

            return new Blob([arr], {
                type: mimeString
            })
        }
    }

    export = async () => {
        this.stop = true
        const oldAfterRender = this.scene.onAfterRender
        const waitNextRender = () => new Promise(resolve =>
            this.scene.onAfterRender = () => requestAnimationFrame(resolve)
        )
        const jobs = this.state.jobs.map(j => ({
            ...j,
            activated: false,
        }))

        const allConditions = generateAllCondition(jobs)
        const textConditions = allConditions.map(r => r.sort().join('_'))
        const filtered = allConditions
            .filter((r, i) => !textConditions.indexOf(r.sort().join('_')) < i)
        filtered
            .reduce(async (acc, row, i) => {
                await acc
                const evaluatedJobs = jobs.map(job => ({
                    ...job,
                    activated: row.includes(job.technicalKey),
                }))
                updateScene(this.scene, evaluatedJobs)

                const nextRender = waitNextRender()
                this.renderScene()
                await nextRender

                const name = generateMapName(evaluatedJobs)
                const data = dataURIToBlob(this.renderer.domElement.toDataURL('image/png'))
                downloadURI(URL.createObjectURL(data), name)
            })

        this.scene.onAfterRender = oldAfterRender

        function downloadURI(uri, name) {
            const link = document.createElement('a')
            link.download = name
            link.href = uri
            document.body.appendChild(link)
            link.click()
            URL.revokeObjectURL(uri)
            document.body.removeChild(link)
        }

        function dataURIToBlob(dataURI) {

            var binStr = atob(dataURI.split(',')[1]),
                len = binStr.length,
                arr = new Uint8Array(len),
                mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

            for (var i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i)
            }

            return new Blob([arr], {
                type: mimeString
            })
        }
    }

    render() {
        return (
            <div>
                {
                    this.state.jobs.map(job =>
                        <button
                            key={job.technicalKey}
                            onClick={() => this.changeJobState(job)}
                            style={{backgroundColor: job.activated ? 'green' : 'red'}}
                        >{job.technicalKey}</button>)
                }
                <button onClick={this.export}>export</button>
                <button onClick={this.test}>test</button>
                <InsertMe style={{transformOrigin: 'top left', transform: 'scale(0.15)'}} onRefChange={(mount) => {
                    this.mount = mount
                }} key={'map'}/>
            </div>
        )
    }
}

class InsertMe extends Component {
    onRefChange = (ref) => {
        this.props.onRefChange && this.props.onRefChange(ref)
    }

    shouldComponentUpdate() {
        return false
    }

    render() {
        const {onRefChange, ...props} = this.props
        return <div {...props} ref={this.onRefChange}/>
    }
}


function activatedMap(jobs) {
    return jobs.reduce((acc, {technicalKey, activated}) => ({
        ...acc,
        [technicalKey]: !!activated,
    }), {})
}

const toIgnore = ['Bone', 'SkinnedMesh']

function updateScene(scene, jobs) {
    const jobsMap = activatedMap(jobs)
    scene.traverse((obj) => {

        if (toIgnore.includes(obj.type)) return

        updateObjFromActivateds(obj, jobsMap)
    })
}

function updateObjFromActivateds(obj, jobMap) {
    obj.visible = shouldShow(obj, jobMap)
}

function extractCondition(name) {
    if (!name) return {}
    return name
        .replace(/ /, '')
        .replace(/[^(]*(?:\(((?:,?[^)]+)+)\))?.*/, '$1')
        .split(',')
        .filter(Boolean)
        .reduce((acc, intermediateKey) => {
            const key = intermediateKey.replace('!', '')
            return {
                ...acc,
                [key]: key !== intermediateKey,
            }
        }, {})
}

function shouldShow(obj, jobsMap) {
    const conditions = extractCondition(obj.name)
    const jobKeys = Object.keys(jobsMap)
    const [name] = obj.name.split(/\(|_/)
    let nameRule = true
    if (jobKeys.includes(name)) {
        nameRule = !!jobsMap[name]
    }

    const conditionsKeys = Object.keys(conditions)
        .filter(key => jobsMap[key] != null)
    const b = conditionsKeys
        .reduce((acc, key) => acc && ((jobsMap[key] == null || jobsMap[key]) === conditions[key]), true)
    return b && nameRule
}


function generateAllCondition(jobs) {
    const baseJobs = jobs.filter(job => !job.dependsOn)

    const jobsRows = baseJobs.map(baseJob => [baseJob, ...nextJobs(baseJob, jobs)])

    return [Array(jobsRows.length).fill(0)].concat(generateSubCombination(jobsRows.map(row => row.length + 1)))
        .map((row) => row
            .map((i, j) => jobsRows[j] && range(i).map(i => jobsRows[j][i]?.technicalKey))
            .reduce((acc, item) => acc.concat(item), [])
        )
}

function range(to) {
    return Array(to)
        .fill(1)
        .map((_, i) => i)
}


function nextJobs(baseJob, jobs) {

    return tap(jobs
        .filter(job => job.dependsOn && job.dependsOn.indexOf(baseJob.technicalKey) >= 0))
        .map(job => [job].concat(nextJobs(job, jobs)))
        .reduce((acc, item) => acc.concat(item), [])
}

function tap(v) {
    console.log(v)
    return v
}

function generateSubCombination(maxes) {
    if (maxes.length <= 0) return []
    const [max, ...restMaxes] = maxes

    return Array(max)
        .fill(1)
        .map((_, i) => {
                const values = generateSubCombination(restMaxes)
                if (!values?.length) {
                    return i
                } else {
                    return values
                        .map(values => [i].concat(values))
                }
            }
        )
        .reduce((acc, v) => acc.concat(v), [])
}
